
interface IProps {
    size: number;
    // 线段长度
    lineLen: number;
    // 线段宽度
    lineWidth: number;
    onSelect: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function RotateAnchorDot(props: IProps) {
    const { size, lineLen, lineWidth, onSelect } = props

    function handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
        onSelect(e)
    }

    return (
        <>
            <div style={{ position: 'absolute', width: `${lineWidth}px`, height: `${lineLen}px`, left: `50%`, top: `${-lineLen}px`, transform: 'translate(-50%, 0)', backgroundColor: 'black' }}></div>
            <div onMouseDown={handleMouseDown} style={{ cursor: 'crosshair', position: 'absolute', width: `${size}px`, height: `${size}px`, left: `50%`, top: `${-lineLen}px`, transform: 'translate(-50%, 0)', backgroundColor: 'black' }}></div>
        </>

    )
}

export default RotateAnchorDot;