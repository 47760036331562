
const BASE_URL = 'https://www.x2077.top/api';
const BASE_PATH = '/api/wx';

const WECHAT_LOGIN_CALLBACK_URL = `${BASE_URL}/wx/callback`
const WECHAT_LOGIN_CHECK_PATH = `${BASE_PATH}/checkLogin`

const URLS = {
    WECHAT_LOGIN_CALLBACK_URL,
    WECHAT_LOGIN_CHECK_PATH,
}

export default URLS;