import './Toolbar.css';
import { Segmented } from 'antd';
import { FileStatus } from '@/FileCenter';
import useFileStatus from '@/components/hooks/fileStatusHook';

interface IProps {
    showResultListView: boolean;
    setShowResultListView: (showResultListView: boolean) => void;
}

function Toolbar(props: IProps) {

    const fileInfoList = useFileStatus().fileInfoList;
    const successCount = fileInfoList.filter(fileInfo => fileInfo.status === FileStatus.Success).length;
    const failureCount = fileInfoList.filter(fileInfo => fileInfo.status === FileStatus.Failure).length;
    const pendingCount = fileInfoList.filter(fileInfo => fileInfo.status === FileStatus.Pending).length;
    const readyCount = fileInfoList.filter(fileInfo => fileInfo.status === FileStatus.Ready).length;

    const handleChange = (value: string) => {
        const flag = value === 'ResultListView';
        props.setShowResultListView(flag);
    };

    return (
        <div className="toolbar">
            <div>
                <span className='file-status-item'>文件总数：{fileInfoList.length}</span>
                <span className='file-status-item'>成功：{successCount}</span>
                <span className='file-status-item'>失败：{failureCount}</span>
                <span className='file-status-item'>正在处理：{pendingCount}</span>
                <span className='file-status-item'>未处理：{readyCount}</span>
            </div>
            <Segmented
                options={[
                    { label: '图片预览', value: 'ImageEditView', },
                    { label: '任务列表', value: 'ResultListView', },
                ]}
                value={props.showResultListView ? 'ResultListView' : 'ImageEditView'}
                onChange={handleChange}
            />
        </div>
    );
}

export default Toolbar;