import { MenuProps } from "antd";

const fontOptions = [
    {
        value: 'Microsoft YaHei',
        label: '微软雅黑',
    },
    {
        value: 'SimSun',
        label: '宋体',
    },
    {
        value: 'SimHei',
        label: '黑体',
    },
    {
        value: 'Arial Black',
        label: 'Arial Black',
    }, {
        value: 'PingFang SC',
        label: '苹方',
    }, {
        value: 'STHeiti',
        label: '华文黑体'
    }
]

const fontSizeOptions = [
    {
        value: 14,
        label: '14px',
    },
    {
        value: 16,
        label: '16px',
    },
    {
        value: 18,
        label: '18px',
    },
    {
        value: 20,
        label: '20px',
    },
    {
        value: 24,
        label: '24px',
    },
    {
        value: 28,
        label: '28px',
    },
    {
        value: 36,
        label: '36px',
    },
    {
        value: 48,
        label: '48px',
    },
    {
        value: 64,
        label: '64px',
    },
    {
        value: 72,
        label: '72px',
    },
    {
        value: 128,
        label: '128px',
    }
]

const dataFormatKeyLabel: { [key: string]: string } = {
    'YYYY-MM-DD': '年-月-日',
    'YYYY/MM/DD': '年/月/日',
    'MM-DD-YYYY': '月-日-年',
    'MM/DD/YYYY': '月/日/年',
    'DD-MM-YYYY': '日-月-年',
    'DD/MM/YYYY': '日/月/年',
    'YYYYMMDD': '年月日',
    'YYYY-MM-DD HH:mm': '年-月-日 时:分',
    'YYYY-MM-DD HH:mm:ss': '年-月-日 时:分:秒',
    'HH:mm': '时:分',
    'HH:mm:ss': '时:分:秒',
}

const menuItems: MenuProps['items'] = [
    {
        label: '年-月-日',
        key: 'YYYY-MM-DD',
    },
    {
        label: '年/月/日',
        key: 'YYYY/MM/DD',
    },
    {
        label: '月-日-年',
        key: 'MM-DD-YYYY',
    },
    {
        label: '月/日/年',
        key: 'MM/DD/YYYY',
    },
    {
        label: '日-月-年',
        key: 'DD-MM-YYYY',
    },
    {
        label: '日/月/年',
        key: 'DD/MM/YYYY',
    },
    {
        label: '年月日',
        key: 'YYYYMMDD',
    },
    {
        label: '年-月-日 时:分',
        key: 'YYYY-MM-DD HH:mm',
    },
    {
        label: '年-月-日 时:分:秒',
        key: 'YYYY-MM-DD HH:mm:ss',
    },
    {
        label: '时:分',
        key: 'HH:mm',
    },
    {
        label: '时:分:秒',
        key: 'HH:mm:ss',
    }
];

function getDateFormatLabel(key: string) {
    return key in dataFormatKeyLabel ? dataFormatKeyLabel[key] : ''
}

export {
    fontOptions,
    fontSizeOptions,
    menuItems,
    getDateFormatLabel,
}