
import './Sidebar.css';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Radio, Space, Select, ColorPicker, Slider, Col, Button, Row, Alert, Dropdown, message, Popover, Tooltip } from 'antd';
import { BoldOutlined, DownOutlined, ItalicOutlined } from '@ant-design/icons';
import type { Color } from 'antd/es/color-picker';
import type { MenuProps, RadioChangeEvent } from 'antd';
import GridSelector from './GridSelector';
import { FileCenterContext, IFileCenterContext } from '@/FileCenterProvider';
import { canvas2Blob, canvas2Img, collectCanvas, download, isSupportFileSystemHandle } from '@/utils';
import { fileCenter, FileInfo, FileStatus } from '@/FileCenter';
import CustomLeftTop from './CustomLeftTop';
import { fontOptions, getDateFormatLabel, menuItems } from './constants';
import CustomEvents from '@/constants/customEvents';
import FileProcess from './FileProcess/FileProcess';
import functionSwitchConfig from '@/functionSwitchConfig';

function Sidebar() {
    const refTextArea = useRef<HTMLTextAreaElement>(null);
    const [dateFormat, setDateFormat] = useState('YYYY-MM-DD');
    const [showProcessModal, setShowProcessModal] = useState(false);

    const {
        isLoggedIn,
        fileIdList,
        waterMarkType,
        setWaterMarkType,
        waterMarkTemplate,
        setWaterMarkTemplate,
        fontName,
        setFontName,
        fontSize,
        setFontSize,
        fontColor,
        setFontColor,
        fontStrokeColor,
        setFontStrokeColor,
        fontStrokeWidth,
        setFontStrokeWidth,
        isBold,
        isItalic,
        setIsBold,
        setIsItalic,
        angel,
        setAngel,
        tran,
        setTran,
        posType,
        setPosType,
        offsetLeft,
        setOffsetLeft,
        offsetTop,
        setOffsetTop,
        cellIndex,
        setCellIndex,
        imageEditViewWH,
    }
        = useContext<IFileCenterContext>(FileCenterContext);

    const handleCloseProcessModal = useCallback(() => {
        setShowProcessModal(false)
    }, [])

    const handleWaterMarkTypeChange = useCallback((e: RadioChangeEvent) => {
        setWaterMarkType(e.target.value)
    }, [setWaterMarkType])

    const handleTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setWaterMarkTemplate(e.target.value)
    }, [setWaterMarkTemplate])

    const handleCellIndexChange = useCallback((index: number) => {
        let left = 50;
        let top = 50;
        switch (index) {
            case 0:
                left = 20;
                top = 20;
                break;
            case 1:
                left = 50;
                top = 20;
                break;
            case 2:
                left = 80;
                top = 20;
                break;
            case 3:
                left = 20;
                top = 50;
                break;
            case 4:
                left = 50;
                top = 50;
                break;
            case 5:
                left = 80;
                top = 50;
                break;
            case 6:
                left = 20;
                top = 80;
                break;
            case 7:
                left = 50;
                top = 80;
                break;
            case 8:
                left = 80;
                top = 80;
                break;
            default:
                left = 50;
                top = 50;
        }
        setCellIndex(index);
        setOffsetLeft(left);
        setOffsetTop(top);
    }, [setCellIndex, setOffsetLeft, setOffsetTop])

    const handlePosTypeChange = useCallback((e: RadioChangeEvent) => {
        setPosType(e.target.value);
        if (e.target.value === 1) {
            handleCellIndexChange(4)
        }
    }, [handleCellIndexChange, setPosType])

    const handleColorChange = useCallback((color: Color) => {
        setFontColor(color.toHexString());
    }, [setFontColor])

    const handleFontStrokeColorChange = useCallback((color: Color) => {
        setFontStrokeColor(color.toHexString());
    }, [setFontStrokeColor])

    const handleClear = useCallback(() => {
        setFontStrokeColor('')
    }, [setFontStrokeColor])

    const handleBoldClick = useCallback(() => {
        setIsBold(!isBold)
    }, [isBold, setIsBold])

    const handleItalicClick = useCallback(() => {
        setIsItalic(!isItalic)
    }, [isItalic, setIsItalic])

    const handleExport = useCallback(() => {
        if (!isLoggedIn) {
            CustomEvents.dispatch(CustomEvents.OPEN_LOGIN_PANEL)
            return
        }
        if (fileIdList.length === 0) {
            message.warning('请先添加图片')
            return
        }
        fileCenter.setAllFilStatus(FileStatus.Pending)
        setShowProcessModal(true)
        collectCanvas({ imageEditViewWH, waterMarkTemplate, fontName, fontSize, fontColor, fontStrokeColor, fontStrokeWidth, isBold, isItalic, angel, tran, offsetLeft, offsetTop, posType }, true,
            async (canvas: HTMLCanvasElement, fileInfo: FileInfo) => {
                const originImageType = fileInfo.getImageType()
                const saveImageType = originImageType === 'unknown' ? 'image/jpeg' : originImageType
                const objectUrl = await canvas2Img(canvas, saveImageType);
                download(objectUrl, fileInfo);
            })
    }, [angel, fileIdList.length, fontColor, fontStrokeColor, fontStrokeWidth, fontName, fontSize, imageEditViewWH, isBold, isItalic, isLoggedIn, offsetLeft, offsetTop, posType, tran, waterMarkTemplate])


    const handleReplace = useCallback(() => {
        if (!isLoggedIn) {
            CustomEvents.dispatch(CustomEvents.OPEN_LOGIN_PANEL)
            return
        }
        if (fileIdList.length === 0) {
            message.warning('请先添加图片')
            return
        }
        fileCenter.setAllFilStatus(FileStatus.Pending)
        setShowProcessModal(true)
        collectCanvas({ imageEditViewWH, waterMarkTemplate, fontName, fontSize, fontColor, fontStrokeColor, fontStrokeWidth, isBold, isItalic, angel, tran, offsetLeft, offsetTop, posType }, false,
            async (canvas: HTMLCanvasElement, fileInfo: FileInfo) => {
                const originImageType = fileInfo.getImageType()
                const saveImageType = originImageType === 'unknown' ? 'image/jpeg' : originImageType
                const blob = await canvas2Blob(canvas, saveImageType);
                if (!blob) {
                    fileInfo.setStatus(FileStatus.Failure);
                    return
                }
                const fileHandle = fileInfo.fileHandle as any;
                if (!fileHandle) {
                    fileInfo.setStatus(FileStatus.Failure);
                    return
                }
                const writableStream = await fileHandle.createWritable();
                // 4. 写入新内容（可以根据需要在原内容上进行修改）
                await writableStream.write(blob);
                // 5. 关闭写入流，完成写入操作
                await writableStream.close();
                fileInfo.setStatus(FileStatus.Success);
            })
    }, [angel, fileIdList.length, fontColor, fontStrokeColor, fontStrokeWidth, fontName, fontSize, imageEditViewWH, isBold, isItalic, isLoggedIn, offsetLeft, offsetTop, posType, tran, waterMarkTemplate])

    const renderPosCom = useCallback(() => {
        if (posType === 2) {
            return (<CustomLeftTop />)
        }

        if (posType === 1) {
            return (
                <div className='grid-selector-container'>
                    <GridSelector value={cellIndex} onChange={handleCellIndexChange} />
                </div>
            )

        }
    }, [cellIndex, handleCellIndexChange, posType,])

    const handleAddPhotoDate = useCallback(() => {
        if (!refTextArea.current) {
            return
        }
        const textarea = refTextArea.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        console.log('start ', start, 'end ', end)
        const value = waterMarkTemplate.substring(0, start) + `{${dateFormat}}` + waterMarkTemplate.substring(end)
        setWaterMarkTemplate(value)
    }, [waterMarkTemplate, dateFormat, setWaterMarkTemplate])


    const handleMenuClick: MenuProps['onClick'] = (e) => {
        setDateFormat(e.key)
    };

    const menuProps = {
        items: menuItems,
        onClick: handleMenuClick,
        selectedKeys: [dateFormat]
    };

    const isReplaceDisabled = !isSupportFileSystemHandle();

    return (
        <div className='sidebar-container'>
            <div className='setting-container'>
                <div className='line-center'>
                    <Radio.Group className='water-mark-type' value={waterMarkType} onChange={handleWaterMarkTypeChange}>
                        <Radio.Button className='water-mark-type-btn' value="text">文字水印</Radio.Button>
                        <Popover placement="top" content="图片水印功能正在开发中，敬请期待">
                            <Radio.Button disabled={true} className='water-mark-type-btn' value="image">图片水印</Radio.Button>
                        </Popover>
                    </Radio.Group>
                </div>
                <div className='line-left label'>文本添加</div>
                <textarea ref={refTextArea} className='sidebar-textarea' placeholder='请输入...' rows={5} value={waterMarkTemplate} onChange={handleTextChange} >
                </textarea>
                <Dropdown.Button className='add-photo-date-btn' menu={menuProps} onClick={handleAddPhotoDate} icon={<DownOutlined />}>
                    添加拍摄日期({getDateFormatLabel(dateFormat)})
                </Dropdown.Button>
                <div className='line-left label'>字体设置</div>
                <div className='line-center'></div>
                <Space className='space-cs'>
                    <Select className='font-name-select' value={fontName} onChange={setFontName} options={fontOptions} />
                    <Row className='font-style-container'>
                        <Col span={6}>
                            <Tooltip placement="bottom" title="设置字体颜色" arrow={true}>
                                <ColorPicker value={fontColor} onChange={handleColorChange} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip placement="bottom" title="加粗" arrow={true}>
                                <Button icon={<BoldOutlined />} onClick={handleBoldClick} style={{ backgroundColor: isBold ? 'rgba(13, 13, 13,0.2)' : 'aliceblue' }} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip placement="bottom" title="斜体" arrow={true}>
                                <Button icon={<ItalicOutlined />} onClick={handleItalicClick} style={{ backgroundColor: isItalic ? 'rgba(13, 13, 13,0.2)' : 'aliceblue' }} />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip placement="bottom" title="设置字体轮廓颜色" arrow={true}>
                                <ColorPicker value={fontStrokeColor} onChange={handleFontStrokeColorChange} onClear={handleClear} allowClear />
                            </Tooltip>
                        </Col>
                    </Row>
                </Space>
                <Row>
                    <Col span={6} className='text'>
                        字体大小
                    </Col>
                    <Col span={18}>
                        <Slider
                            min={6}
                            max={150}
                            step={1}
                            onChange={setFontSize}
                            value={fontSize}
                        />
                    </Col>
                </Row>
                {functionSwitchConfig.enableCustomFontStrokeWidth && (
                    <Row>
                        <Col span={6} className='text'>
                            轮廓粗细
                        </Col>
                        <Col span={18}>
                            <Slider
                                min={0}
                                max={10}
                                step={1}
                                onChange={setFontStrokeWidth}
                                value={fontStrokeWidth}
                            />
                        </Col>
                    </Row>
                )}
                <div className='line-left label'>布局</div>
                <Row>
                    <Col span={6} className='text'>
                        方向
                    </Col>
                    <Col span={14}>
                        <Slider
                            min={-180}
                            max={180}
                            step={10}
                            onChange={setAngel}
                            value={typeof angel === 'number' ? angel : 0}
                        />
                    </Col>
                    <Col span={4} className='text flex-end'>{angel}°</Col>
                </Row>
                <Row>
                    <Col span={6} className='text'>
                        透明度
                    </Col>
                    <Col span={14}>
                        <Slider
                            min={0}
                            max={100}
                            step={1}
                            onChange={setTran}
                            value={tran}
                        />
                    </Col>
                    <Col span={4} className='text flex-end'>{tran}%</Col>
                </Row>
                <Row className='pos-type-row'>
                    <Radio.Group onChange={handlePosTypeChange} value={posType}>
                        <Radio value={0}>平铺</Radio>
                        <Radio value={1}>九宫格</Radio>
                        <Radio value={2}>自定义</Radio>
                    </Radio.Group>
                </Row>
                {renderPosCom()}
            </div>

            <div className='near-bottom'>
                <Button disabled={isReplaceDisabled} size='large' className='near-bottom-btn' onClick={handleReplace}>批量替换</Button>
                <Button size='large' type='primary' onClick={handleExport}>批量导出</Button>
            </div>
            {isReplaceDisabled && <Alert className='alert-warning'
                message="当前浏览器不支持批量替换，请使用最新的chrome、edge浏览器"
                type="warning"
                closable
            />}
            {showProcessModal && <FileProcess showProcessModal={showProcessModal} onProcessClose={handleCloseProcessModal} />}
        </div>
    )
}

export default React.memo(Sidebar)