import './ResultList.css';
import { Button, Table } from "antd";
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { fileCenter, FileInfo } from "@/FileCenter";
import { formatFileSize, getFileStatusText } from "@/utils";
import useFileStatus from '@/components/hooks/fileStatusHook';

interface IProps {
    onRemove: (fileId: string) => void;
    setShowResultListView: (showResultListView: boolean) => void;
    setSelectedFileInfo: (fileInfo: FileInfo | undefined) => void;
}

function ResultList(props: IProps) {
    const { onRemove, setShowResultListView, setSelectedFileInfo } = props
    const fileInfoList = useFileStatus().fileInfoList;

    const handleRemove = (e: any) => {
        const id = e.currentTarget.getAttribute('data-id');
        onRemove(id);
    }

    const handleFind = (e: any) => {
        const id = e.currentTarget.getAttribute('data-id');
        const fileInfo = fileCenter.getFileInfo(id)
        setSelectedFileInfo(fileInfo)
        setShowResultListView(false)
    }

    const columns = [
        {
            title: '文件名',
            dataIndex: 'file.name',
            key: 'name',
            render: (text: any, fileInfo: FileInfo) => fileInfo.file.name,
        },
        {
            title: '图片尺寸',
            dataIndex: 'image',
            key: 'size',
            render: (text: any, fileInfo: FileInfo) => `${fileInfo.image.width}x${fileInfo.image.height}`,
        },
        {
            title: '图片大小',
            dataIndex: 'file.size',
            key: 'size',
            render: (text: any, fileInfo: FileInfo) => formatFileSize(fileInfo.file.size),
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, fileInfo: FileInfo) => getFileStatusText(fileInfo.status),
        },
        {
            title: '操作',
            key: 'action',
            render: (text: any, fileInfo: FileInfo) => (
                <div className='option-btn-container'>
                    <Button data-id={fileInfo.fileId} size='small' shape="circle" icon={<SearchOutlined />} onClick={handleFind} />
                    <Button data-id={fileInfo.fileId} size='small' shape="circle" icon={<DeleteOutlined />} onClick={handleRemove} />
                </div>
            ),
        },
    ];

    return (
        <Table
            className='result-list'
            dataSource={fileInfoList}
            columns={columns}
            rowKey="file.id"
            pagination={{
                pageSize: 20, // 默认每页展示10条记录
                showSizeChanger: true, // 允许用户设置每页展示的记录数
                pageSizeOptions: ['10', '20', '50', '100'], // 用户可以选择的每页展示记录数选项
            }}
        />
    );
}

export default ResultList;