import './ImageEditView.css';
import React, { createRef } from 'react';
import { FileInfo } from '@/FileCenter';
import { IFileCenterContext } from '@/FileCenterProvider';
import { getImageRatio, getRotatedElementCorners, getString, getTextWH, throttle } from '@/utils';
import type { Rect } from '@/utils';
import AnchorDot, { AnchorDotType, getAnchorDotInfo } from './components/AnchorDot/AnchorDot';
import RotateAnchorDot from './components/AnchorDot/RotateAnchorDot';


interface IProps extends IFileCenterContext {
    selectedItem: FileInfo | undefined,
}

interface IState {
    left: number;
    top: number;
    width: number;
    height: number;
    cursorStyle: string | undefined;
    hasOutline: boolean;
}



class ImageEditView extends React.PureComponent<IProps, IState> {
    private readonly AnchorDotSize = 8;

    private ratio = 1;
    private startMoveFlag = false;
    private startSetSizeProps: any = null;
    private startRotateProps: any = null;

    private xOffsetWaterMark = 0;
    private yOffsetWaterMark = 0;
    private waterMarkContainerRect: Rect | undefined = undefined
    private refWaterMarkContainer = createRef<HTMLDivElement>();
    private refWaterMark = createRef<HTMLDivElement>()
    private refContainer = createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            cursorStyle: undefined,
            hasOutline: false,
        };

    }

    componentDidMount(): void {
        window.addEventListener('resize', this.updateSize)
        window.addEventListener('mousemove', this.handleMouseMove)
        window.addEventListener('mouseup', this.handleMouseUp)

        this.updateSize()
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateSize)
        window.removeEventListener('mousemove', this.handleMouseMove)
        window.removeEventListener('mouseup', this.handleMouseUp)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            this.updateSize()
        }
    }

    private getWaterMark() {
        const { waterMarkTemplate,
            selectedItem,
        } = this.props
        if (!selectedItem) return ''
        const imageTakenTime = selectedItem.imageTakenTime
        return getString(waterMarkTemplate, imageTakenTime)
    }

    private getWaterMarkWH() {
        const {
            fontName,
            fontSize,
            isBold,
            isItalic,
        } = this.props
        const waterMark = this.getWaterMark()
        return getTextWH(waterMark, { fontFamily: fontName, fontSize, isBold, isItalic })
    }

    private updateSize = () => {
        const container = this.refContainer.current;
        const selectedItem = this.props.selectedItem;
        if (!container || !selectedItem) return;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const image = selectedItem.image;
        const originWidth = image.width;
        const originHeight = image.height;
        this.ratio = getImageRatio(originWidth, originHeight, containerWidth, containerHeight);
        const adaptiveWidth = this.ratio * originWidth;
        const adaptiveHeight = this.ratio * originHeight;
        const left = (containerWidth - adaptiveWidth) / 2;
        const top = (containerHeight - adaptiveHeight) / 2;
        this.setState({ left, top, width: adaptiveWidth, height: adaptiveHeight })
        this.props.setImageEditViewWH({ width: containerWidth, height: containerHeight })
    }

    private handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        // 在水印文字上mousdown,不能让outline消失
        event.stopPropagation()
        this.startMoveFlag = true

        if (!this.refWaterMarkContainer.current) {
            return;
        }
        this.waterMarkContainerRect = this.refWaterMarkContainer.current.getBoundingClientRect()

        if (!this.refWaterMark.current) {
            return;
        }
        const waterMarkRect = this.refWaterMark.current.getBoundingClientRect()
        this.xOffsetWaterMark = event.clientX - waterMarkRect.left
        this.yOffsetWaterMark = event.clientY - waterMarkRect.top
        this.setState({ hasOutline: true })
    }

    private handleWaterMarkContainerMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        // 在水印文字外面mousedown，要让outline消失
        this.setState({ hasOutline: false })
    }

    private handleRotateAnchorDotMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!this.refWaterMark.current) return;
        const waterMarkRect = this.refWaterMark.current.getBoundingClientRect()
        const { left, top, width, height, bottom, right } = waterMarkRect

        this.startRotateProps = {
            waterMarkRect: { left, top, width, height, bottom, right },
        }
        this.setState({ cursorStyle: 'crosshair' })
        event.stopPropagation()
    }

    /*
    如果不加throttle, CustomLeftTop的 antd Slider组件会一直更新，导致控制台出现如下警告：
    Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect, but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render. 
    */

    private handleMouseMove = throttle((event: MouseEvent) => {
        if (this.startMoveFlag) {
            this.moveWaterMark(event);
            return;
        }

        if (this.startSetSizeProps) {
            this.adjustWaterMarkSize(event);
            return;
        }

        if (this.startRotateProps) {
            this.rotateWaterMark(event);
            return;
        }
    }, 25)

    private moveWaterMark = (event: MouseEvent) => {
        if (!this.waterMarkContainerRect || !this.waterMarkContainerRect) return;
        const waterMark = this.refWaterMark.current
        if (!waterMark) {
            return;
        }
        // 获取水印矩形的包围盒，不能直接用水印内容的宽度，因为存在在旋转后
        const waterMarkRect = waterMark.getBoundingClientRect()
        const { width, height } = this.state

        const x = event.clientX - this.waterMarkContainerRect.left - this.xOffsetWaterMark
        const y = event.clientY - this.waterMarkContainerRect.top - this.yOffsetWaterMark
        const ratioDx = (x + waterMarkRect.width / 2) / width * 100
        const ratioDy = (y + waterMarkRect.height / 2) / height * 100
        const newOffsetLeft = Math.min(100, Math.max(0, ratioDx))
        const newOffsetTop = Math.min(100, Math.max(0, ratioDy))
        this.props.setOffsetLeft(newOffsetLeft)
        this.props.setOffsetTop(newOffsetTop)
    }

    private adjustWaterMarkSize = (event: MouseEvent) => {
        if (!this.startSetSizeProps || !this.waterMarkContainerRect) return;
        const { fontName, isBold, isItalic } = this.props;
        const waterMark = this.getWaterMark()
        // 获取缩放前，旋转后元素的4个顶点的位置和宽高
        const { anchorDotType, waterMarkRect, fontSize, rotatedElementCorners, offsetWidth, offsetHeight } = this.startSetSizeProps;
        // 获取缩放前，矩形中心点的位置(相对于浏览器)，旋转前后中心点位置不变
        const centerX = waterMarkRect.left + waterMarkRect.width / 2;
        const centerY = waterMarkRect.top + waterMarkRect.height / 2;
        const originDistance = Math.sqrt(offsetWidth * offsetWidth + offsetHeight * offsetHeight);
        const clientX = event.clientX;
        const clientY = event.clientY;
        // 获取缩放前，对角点的位置
        const { diagonalAnchorX, diagonalAnchorY } = this.getAnchorDiagonal(anchorDotType, rotatedElementCorners);
        const distance = Math.sqrt((clientX - diagonalAnchorX) * (clientX - diagonalAnchorX) + (clientY - diagonalAnchorY) * (clientY - diagonalAnchorY));
        // 获取原始缩放比
        const originRatio = distance / originDistance;
        // 获取新的字体大小，可能为小数
        const newFontSize = fontSize * originRatio;
        // 字体大小取整
        const newFontSizeNum = Math.round(newFontSize)
        // 得到最终水印的宽高
        const { width: newWaterMarkWidth } = getTextWH(waterMark, { fontFamily: fontName, fontSize: newFontSizeNum, isBold, isItalic })
        // 获取最终缩放比
        const zoomRatio = newWaterMarkWidth / offsetWidth;
        // 计算缩放后，中心位置
        const newCenterX = (centerX - diagonalAnchorX) * zoomRatio + diagonalAnchorX;
        const newCenterY = (centerY - diagonalAnchorY) * zoomRatio + diagonalAnchorY;
        const newOffsetLeft = (newCenterX - this.waterMarkContainerRect.left) / this.state.width * 100;
        const newOffsetTop = (newCenterY - this.waterMarkContainerRect.top) / this.state.height * 100;

        this.props.setFontSize(newFontSizeNum);
        this.props.setOffsetLeft(newOffsetLeft)
        this.props.setOffsetTop(newOffsetTop)
    }

    private rotateWaterMark(event: MouseEvent) {
        if (!this.waterMarkContainerRect || !this.startRotateProps) return;
        const clientX = event.clientX;
        const clientY = event.clientY;
        const waterMarkRect = this.startRotateProps.waterMarkRect
        const waterMarkCenterX = waterMarkRect.left + waterMarkRect.width / 2
        const waterMarkCenterY = waterMarkRect.top + waterMarkRect.height / 2

        // tempAngel为向量与-y方向的夹角
        const tempAngel = Math.atan2(clientX - waterMarkCenterX, clientY - waterMarkCenterY) * 180 / Math.PI
        const angel = tempAngel < 0 ? -180 - tempAngel : 180 - tempAngel
        this.props.setAngel(Math.round(angel))
    }

    private handleMouseUp = () => {
        this.startMoveFlag = false;
        this.startSetSizeProps = null;
        this.startRotateProps = null;
        this.setState({ cursorStyle: undefined })
    }

    private getAnchorDiagonal(anchorDotType: AnchorDotType, rotatedElementCorners: any[]): { diagonalAnchorX: number, diagonalAnchorY: number } {
        let diagonalDotType = AnchorDotType.LeftTop;
        switch (anchorDotType) {
            case AnchorDotType.LeftTop:
                diagonalDotType = AnchorDotType.RightBottom
                break;
            case AnchorDotType.RightTop:
                diagonalDotType = AnchorDotType.LeftBottom
                break;
            case AnchorDotType.RightBottom:
                diagonalDotType = AnchorDotType.LeftTop
                break;
            case AnchorDotType.LeftBottom:
                diagonalDotType = AnchorDotType.RightTop
                break;
        }
        const diagonalAnchorX = rotatedElementCorners[diagonalDotType].x;
        const diagonalAnchorY = rotatedElementCorners[diagonalDotType].y;
        return { diagonalAnchorX, diagonalAnchorY };
    }

    private handleAnchorDotSelect = (anchorDotType: AnchorDotType) => {
        const waterMark = this.refWaterMark.current
        if (!waterMark || !this.refWaterMarkContainer.current) return;
        // 获取矩形原始宽高，因为矩形旋转后，getBoundingClientRect为包围盒的矩形
        const offsetWidth = waterMark.offsetWidth;
        const offsetHeight = waterMark.offsetHeight;
        const waterMarkRect = waterMark.getBoundingClientRect()
        const { left, top, width, height, bottom, right } = waterMarkRect

        const rotatedElementCorners = getRotatedElementCorners(waterMark)

        this.waterMarkContainerRect = this.refWaterMarkContainer.current.getBoundingClientRect()

        this.startSetSizeProps = {
            fontSize: this.props.fontSize,
            anchorDotType,
            waterMarkRect: { left, top, width, height, bottom, right },
            offsetWidth,
            offsetHeight,
            rotatedElementCorners,
        }
        this.setState({ cursorStyle: getAnchorDotInfo(anchorDotType, this.AnchorDotSize).cursor })
    }

    public render() {
        const { selectedItem } = this.props
        if (!selectedItem) {
            return null
        }
        const { left, top, width, height, cursorStyle } = this.state

        return (
            <div className='image-edit-view' style={{ cursor: cursorStyle }} ref={this.refContainer}>
                <img className="image-edit-img2" style={{ left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }} src={selectedItem?.fileDataUrl} alt={selectedItem.file.name} />
                <div ref={this.refWaterMarkContainer} className='image-real-size' onMouseDown={this.handleWaterMarkContainerMouseDown} style={{ left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }}>
                    {this.renderWaterMark()}
                </div>
            </div>
        )
    }

    private renderWaterMark() {
        const {
            fontName,
            fontSize,
            fontColor,
            fontStrokeColor,
            fontStrokeWidth,
            angel,
            tran,
            posType,
            offsetLeft,
            offsetTop,
            isBold,
            isItalic,
        } = this.props

        const waterMark = this.getWaterMark()
        const { width, height, cursorStyle, hasOutline } = this.state

        const curFontSize = fontSize
        const { width: waterMarkWidth, height: waterMarkHeight } = this.getWaterMarkWH()
        const waterMarkLeft = offsetLeft / 100 * width - waterMarkWidth / 2
        const waterMarkTop = offsetTop / 100 * height - waterMarkHeight / 2
        const opacity = 1 - tran / 100

        if (posType !== 0) {
            return (
                <div ref={this.refWaterMark} className='water-mark' style={
                    {
                        color: fontColor,
                        // WebkitTextStroke: `${fontStrokeWidth}px ${fontStrokeColor}`,
                        // textShadow: `${fontStrokeColor} -1px 0px,${fontStrokeColor} 0px 1px, ${fontStrokeColor} 1px 0px, ${fontStrokeColor} 0px -1px`,
                        textShadow: fontStrokeColor ? `${fontStrokeColor} -${fontStrokeWidth}px 0px,${fontStrokeColor} 0px ${fontStrokeWidth}px, ${fontStrokeColor} ${fontStrokeWidth}px 0px, ${fontStrokeColor} 0px -${fontStrokeWidth}px` : undefined,
                        left: `${waterMarkLeft}px`,
                        top: `${waterMarkTop}px`,
                        fontSize: `${curFontSize}px`,
                        fontFamily: `"${fontName}" ,serif`,
                        fontWeight: isBold ? 'bold' : 'normal',
                        fontStyle: isItalic ? 'italic' : 'normal',
                        lineHeight: `${waterMarkHeight}px`,
                        transform: `rotate(${angel}deg)`,
                        opacity,
                        cursor: cursorStyle ? cursorStyle : 'move',
                        outline: hasOutline ? '1px solid black' : undefined
                    }
                }
                    onMouseDown={this.handleMouseDown}
                >
                    {hasOutline && <>
                        <AnchorDot anchorDotType={AnchorDotType.LeftTop} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <AnchorDot anchorDotType={AnchorDotType.RightTop} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <AnchorDot anchorDotType={AnchorDotType.LeftBottom} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <AnchorDot anchorDotType={AnchorDotType.RightBottom} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <RotateAnchorDot size={this.AnchorDotSize} lineLen={50} lineWidth={2} onSelect={this.handleRotateAnchorDotMouseDown} />
                    </>}
                    {waterMark}
                </div>
            )
        }

        const list = []
        const start = curFontSize
        const distanceX = curFontSize * 2 + waterMarkWidth
        const distanceY = curFontSize * 2 + waterMarkHeight
        let i = 0;
        while (start + i * distanceX < width) {
            let j = 0;
            while (start + j * distanceY < height) {
                const curWaterMarkLeft = start + i * distanceX
                const curWaterMarkTop = start + j * distanceY
                list.push(
                    <div key={`${i}-${j}`} className='water-mark' style={
                        {
                            color: fontColor,
                            // WebkitTextStroke: `${fontStrokeWidth}px ${fontStrokeColor}`,
                            textShadow: fontStrokeColor ? `${fontStrokeColor} -${fontStrokeWidth}px 0px,${fontStrokeColor} 0px ${fontStrokeWidth}px, ${fontStrokeColor} ${fontStrokeWidth}px 0px, ${fontStrokeColor} 0px -${fontStrokeWidth}px` : undefined,
                            left: `${curWaterMarkLeft}px`,
                            top: `${curWaterMarkTop}px`,
                            fontSize: `${curFontSize}px`,
                            fontFamily: `"${fontName}" ,serif`,
                            fontWeight: isBold ? 'bold' : 'normal',
                            fontStyle: isItalic ? 'italic' : 'normal',
                            lineHeight: `${waterMarkHeight}px`,
                            transform: `rotate(${angel}deg)`,
                            opacity,
                        }
                    }>{waterMark}</div>
                );
                j++
            }
            i++
        }
        return list
    }
}

export default ImageEditView