
import './WorkSpace.css'
import React, { useContext, useState } from 'react';
import UploadPage from './UploadPage';
import ThumbnailList from './ThumbnailList/ThumbnailList'
import ImageEditView from './ImageEditView'
import { FileCenterContext } from '@/FileCenterProvider';
import { fileCenter, FileInfo } from '@/FileCenter';
import ResultList from './ResultList/ResultList';
import Toolbar from './Toolbar/Toolbar';

function WorkSpace() {
    const [showResultListView, setShowResultListView] = useState(false)
    const [selectedFileInfo, setSelectedFileInfo] = useState<FileInfo | undefined>(undefined)
    const { setFileIdList, fileIdList } = useContext(FileCenterContext);

    function handleSelect(fileInfo: FileInfo) {
        setSelectedFileInfo(fileInfo)
    }

    function handleRemove(fileId: string) {
        fileCenter.removeFileInfo(fileId)
        const newFileIdList = fileIdList.filter(eachFileId => eachFileId !== fileId)
        setFileIdList(newFileIdList)
    }

    if (fileIdList.length === 0) {
        return (
            <div className="workspace-container">
                < UploadPage />
            </div>
        )
    }

    const fileInfo = fileCenter.getFileInfo(fileIdList[0])
    // 刚开始用户没有点击右侧的缩略图列表，则默认选中第0个
    const selectedItem = selectedFileInfo || fileInfo
    return (
        <div className="workspace-container">
            <ThumbnailList selectedItem={selectedItem} onSelect={handleSelect} onRemove={handleRemove} />
            <div className="workspace-content">
                <Toolbar showResultListView={showResultListView} setShowResultListView={setShowResultListView} />
                {showResultListView && <ResultList onRemove={handleRemove} setShowResultListView={setShowResultListView} setSelectedFileInfo={setSelectedFileInfo} />}
                {!showResultListView && (
                    <FileCenterContext.Consumer>
                        {(value) => <ImageEditView selectedItem={selectedItem} {...value} />}
                    </FileCenterContext.Consumer>
                )}
            </div>
        </div>
    )

}

export default React.memo(WorkSpace)