import EXIF from 'exif-js';
import { fileCenter, FileInfo, FileStatus } from "./FileCenter";
import type { WH } from './FileCenterProvider';

interface FontProps {
    fontFamily: string;
    fontSize: number;
    isItalic: boolean;
    isBold: boolean;
}

interface IPoint {
    x: number;
    y: number;
}

interface Rect {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface WaterMarkConfig {
    waterMarkTemplate: string,
    fontName: string,
    fontSize: number,
    fontColor: string,
    fontStrokeColor: string,
    fontStrokeWidth: number,
    isBold: boolean,
    isItalic: boolean,
    angel: number,
    tran: number,
    offsetLeft: number,
    offsetTop: number,
    posType: number,
    imageEditViewWH: WH,
}

function initMeasureText() {
    const div = document.createElement('div')
    div.id = 'measure-text'
    div.innerHTML = ""
    div.style.cssText = "visibility: hidden;display: 'inline-block';position:absolute;white-space: nowrap;line-height: 1;"
    document.body.appendChild(div)
}

initMeasureText()


function getImageRatio(originWidth: number, originHeight: number, containerWidth: number, containerHeight: number) {
    if (originWidth <= containerWidth && originHeight <= containerHeight) {
        return 1;
    }

    const widthRatio = containerWidth / originWidth;
    const heightRatio = containerHeight / originHeight;
    return Math.min(widthRatio, heightRatio)
}

function getTextWH2(text: string, font: FontProps) {
    const { fontFamily, fontSize } = font
    const div = document.getElementById('measure-text')
    if (!div) {
        return { width: 0, height: 0 }
    }
    div.innerHTML = text
    div.style.fontFamily = fontFamily
    div.style.fontSize = fontSize + 'px'
    const rect = div.getBoundingClientRect()
    return { width: rect.width, height: rect.height }
}

function getTextWH(text: string, font: FontProps) {
    const textMetrics = measureTextDimensions(text, font)
    if (!textMetrics) {
        return { width: 0, height: 0, textMetrics: { fontBoundingBoxAscent: 0, fontBoundingBoxDescent: 0 } }
    }
    // m.fontBoundingBoxAscent + m.fontBoundingBoxDescent 为行高line-height
    return { width: textMetrics.width, height: textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent, textMetrics }
}

function measureTextDimensions(text: string, fontProps: FontProps) {
    // 创建一个隐藏的canvas元素
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    }
    const { fontFamily, fontSize, isItalic, isBold } = fontProps
    // 设置字体
    ctx.font = `${isItalic ? 'italic' : ''} ${isBold ? 'bold' : ''} ${fontSize}px ${fontFamily},serif`;
    // 测量宽度
    const metrics = ctx.measureText(text);
    return metrics;
}

function createCanvas() {
    const canvas = document.createElement("canvas");
    return canvas
}

function resizeCanvas(canvas: HTMLCanvasElement, width: number, height: number) {
    canvas.width = width
    canvas.height = height
}

function canvas2Img(canvas: HTMLCanvasElement, imgType: string, quality: number = 1) {
    return new Promise<string>((resolve, reject) => {
        canvas.toBlob(function (blob) {
            if (!blob) {
                resolve('')
                return
            }
            var url = URL.createObjectURL(blob);
            resolve(url)
        }, imgType, quality);
    })
}

function canvas2Blob(canvas: HTMLCanvasElement, imgType: string, quality: number = 1) {
    return new Promise<Blob | null>((resolve, reject) => {
        canvas.toBlob(function (blob) {
            if (!blob) {
                resolve(null)
                return
            }
            resolve(blob)
        }, imgType, quality);
    })
}

function download(url: string, fileInfo: FileInfo) {
    // 创建一个临时链接并模拟点击下载
    var a = document.createElement('a');
    a.href = url
    a.download = fileInfo.file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    fileInfo.setStatus(FileStatus.Success)
}

function throttle(func: any, delay: number) {
    let lastTime = 0;

    return (args: any) => {
        const now = Date.now();

        if (now - lastTime >= delay) {
            lastTime = now;
            func(args);
        }
    };
}

async function collectCanvas(waterMarkConfig: WaterMarkConfig, needSerial: boolean, cb: (canvas: HTMLCanvasElement, pendingFileInfo: FileInfo) => void): Promise<void> {
    const canvas = createCanvas();
    const ctx = canvas.getContext('2d');
    if (!ctx) {

        return;
    }

    const { imageEditViewWH, waterMarkTemplate, fontName, fontSize, fontColor, fontStrokeColor, fontStrokeWidth, isBold, isItalic, angel, tran, offsetLeft, offsetTop, posType } = waterMarkConfig
    const fileList = fileCenter.getFileInfoList();

    for (let fileInfo of fileList) {
        if (fileInfo.status !== FileStatus.Pending) {
            // 如果文件不是Pending，则表明用户取消了任务，此时任务状态会变成Ready，不再处理
            continue;
        }
        const { file, image: img } = fileInfo;
        const { width, height } = img;
        const ratio = getImageRatio(width, height, imageEditViewWH.width, imageEditViewWH.height)
        const finalFontSize = Math.round(fontSize / ratio)
        canvas.width = width;
        canvas.height = height;
        // 当你设置 <canvas> 元素的 width 和 height 属性时，会重置画布的内容,并且重置绘图上下文（ctx）

        ctx.fillStyle = fontColor;
        ctx.font = `${isItalic ? 'italic' : ''} ${isBold ? 'bold' : ''} ${finalFontSize}px ${fontName},serif`;
        // ctx.textBaseline = 'top';
        ctx.textBaseline = 'alphabetic';
        ctx.textAlign = 'start';
        if (fontStrokeColor && fontStrokeWidth > 0) {
            ctx.strokeStyle = fontStrokeColor;
            ctx.lineWidth = fontStrokeWidth;
        }

        ctx.drawImage(img, 0, 0, width, height);
        const imageTakenTime = await getImageTakenTime(file)
        const waterMark = getString(waterMarkTemplate, imageTakenTime)
        const { width: waterMarkWidth, height: waterMarkHeight, textMetrics } = getTextWH(waterMark, { fontFamily: fontName, fontSize: finalFontSize, isItalic, isBold });


        if (posType !== 0) {
            const waterMarkCenterX = offsetLeft / 100 * width
            const waterMarkCenterY = offsetTop / 100 * height
            ctx.save()
            // 以水印中心为原点 旋转
            ctx.translate(waterMarkCenterX, waterMarkCenterY);
            ctx.rotate(angel * Math.PI / 180);
            ctx.globalAlpha = 1 - tran / 100;
            ctx.fillText(waterMark, -waterMarkWidth / 2, -waterMarkHeight / 2 + textMetrics.fontBoundingBoxAscent);
            if (fontStrokeColor && fontStrokeWidth > 0) {
                ctx.strokeText(waterMark, -waterMarkWidth / 2, -waterMarkHeight / 2 + textMetrics.fontBoundingBoxAscent);
            }
            ctx.restore()
        } else {
            const start = finalFontSize
            const distanceX = finalFontSize * 2 + waterMarkWidth
            const distanceY = finalFontSize * 2 + waterMarkHeight
            let i = 0;
            while (start + i * distanceX < width) {
                let j = 0;
                while (start + j * distanceY < height) {
                    const curWaterMarkLeft = start + i * distanceX
                    const curWaterMarkTop = start + j * distanceY
                    // 以水印中心为原点 旋转
                    ctx.translate(curWaterMarkLeft + waterMarkWidth / 2, curWaterMarkTop + waterMarkHeight / 2);
                    ctx.rotate(angel * Math.PI / 180);
                    ctx.globalAlpha = 1 - tran / 100;
                    ctx.fillText(waterMark, - waterMarkWidth / 2, -waterMarkHeight / 2 + textMetrics.fontBoundingBoxAscent);
                    if (fontStrokeColor && fontStrokeWidth > 0) {
                        ctx.fillText(waterMark, - waterMarkWidth / 2, -waterMarkHeight / 2 + textMetrics.fontBoundingBoxAscent);
                    }
                    ctx.setTransform(1, 0, 0, 1, 0, 0);
                    j++
                }
                i++
            }
        }
        // 批量替换不能用await来执行
        if (needSerial) {
            await cb(canvas, fileInfo)
        } else {
            cb(canvas, fileInfo)
        }

    }
}

function isSupportFileSystemHandle() {
    return (window as any).showOpenFilePicker && (window as any).DataTransferItem.prototype.getAsFileSystemHandle;
}

// 添加这个新函数来获取图片的拍摄时间
async function getImageTakenTime(file: File): Promise<Date> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            if (!e.target?.result) {
                resolve(new Date(file.lastModified));
                return;
            }

            try {
                const exif = EXIF.readFromBinaryFile(e.target.result);
                if (exif?.DateTimeOriginal) {
                    // EXIF 日期格式通常为 "YYYY:MM:DD HH:mm:ss"
                    const [date, time] = exif.DateTimeOriginal.split(' ');
                    const [year, month, day] = date.split(':');
                    const [hour, minute, second] = time.split(':');
                    resolve(new Date(year, month - 1, day, hour, minute, second));
                } else {
                    // 如果没有拍摄时间，返回文件的最后修改时间
                    resolve(new Date(file.lastModified));
                }
            } catch (error) {
                console.error('Error reading EXIF data:', error);
                // 发生错误时返回文件的最后修改时间
                resolve(new Date(file.lastModified));
            }
        };
        reader.readAsArrayBuffer(file);
    });
}


function getString(waterMarkTemplate: string, date: Date): string {
    const formatMap: { [key: string]: string } = {
        'YYYY': date.getFullYear().toString(),
        'MM': (date.getMonth() + 1).toString().padStart(2, '0'),
        'DD': date.getDate().toString().padStart(2, '0'),
        'HH': date.getHours().toString().padStart(2, '0'),
        'mm': date.getMinutes().toString().padStart(2, '0'),
        'ss': date.getSeconds().toString().padStart(2, '0')
    };

    return waterMarkTemplate.replace(/\{([^}]+)\}/g, (match, p1) => {
        let result = p1;
        for (const [placeholder, value] of Object.entries(formatMap)) {
            result = result.replace(placeholder, value);
        }
        return result;
    });
}

// 封装请求方法
async function request(url: string, options: RequestInit = {}) {
    const token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
        // 在请求头中携带 token
        'Authorization': `Bearer ${token}`,
        ...options.headers,
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    return response.json();
}

function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

function getFileStatusText(fileStatus: FileStatus): string {
    switch (fileStatus) {
        case FileStatus.Pending:
            return '处理中';
        case FileStatus.Success:
            return '成功';
        case FileStatus.Failure:
            return '失败';
        default:
            return '等待处理';
    }
}

// 获取旋转后的元素四个顶点坐标(相对于浏览器)
function getRotatedElementCorners(element: HTMLDivElement) {
    // 获取包围盒
    const rect = element.getBoundingClientRect();

    // 获取包围盒宽高
    const width = rect.width;
    const height = rect.height;

    // 获取中心点
    const cx = rect.left + width / 2;
    const cy = rect.top + height / 2;

    // 获取 transform 属性
    const style = window.getComputedStyle(element);
    const transform = style.transform;

    if (transform === "none") {
        // 没有旋转的情况，直接返回边框
        return [
            { x: rect.left, y: rect.top },
            { x: rect.right, y: rect.top },
            { x: rect.right, y: rect.bottom },
            { x: rect.left, y: rect.bottom },
        ];
    }

    // 解析 transform 矩阵
    const res = transform.match(/matrix\(([^)]+)\)/)
    if (!res) {
        return [
            { x: rect.left, y: rect.top },
            { x: rect.right, y: rect.top },
            { x: rect.right, y: rect.bottom },
            { x: rect.left, y: rect.bottom },
        ];
    }
    const matrix = res[1].split(',').map(parseFloat);
    const [a, b, c, d, e, f] = matrix;

    const originWdith = element.offsetWidth;
    const originHeight = element.offsetHeight;
    // 计算原始顶点（未旋转）
    const corners = [
        { x: -originWdith / 2, y: -originHeight / 2 }, // 左上角
        { x: originWdith / 2, y: -originHeight / 2 },  // 右上角
        { x: originWdith / 2, y: originHeight / 2 },   // 右下角
        { x: -originWdith / 2, y: originHeight / 2 },  // 左下角
    ];


    // 应用旋转矩阵，计算旋转后的顶点坐标
    return corners.map(({ x, y }) => ({
        x: cx + a * x + c * y,
        y: cy + b * x + d * y,
    }));
}


export {
    formatFileSize,
    request,
    getImageRatio,
    getTextWH,
    createCanvas,
    resizeCanvas,
    canvas2Img,
    canvas2Blob,
    download,
    throttle,
    collectCanvas,
    isSupportFileSystemHandle,
    getString,
    getImageTakenTime,
    getFileStatusText,
    getRotatedElementCorners,
};
export type { IPoint, Rect, WaterMarkConfig };
