import { fileCenter, FileInfo } from "@/FileCenter";
import { FileCenterContext, IFileCenterContext } from "@/FileCenterProvider";
import { useContext, useEffect, useState } from "react";

function useFileStatus() {

    const { fileIdList } = useContext<IFileCenterContext>(FileCenterContext);
    const [fileInfoList, setFileInfoList] = useState<FileInfo[]>([]);

    useEffect(() => {
        const updatedFileInfoList = fileIdList.map(fileId => fileCenter.getFileInfo(fileId)).filter(fileInfo => !!fileInfo) as FileInfo[];
        setFileInfoList(updatedFileInfoList);
    }, [fileIdList]);

    useEffect(() => {
        const handleStatusChange = () => {
            const updatedFileInfoList = fileIdList.map(fileId => fileCenter.getFileInfo(fileId)).filter(fileInfo => !!fileInfo) as FileInfo[];
            setFileInfoList(updatedFileInfoList);
        };

        fileCenter.on('statusChange', handleStatusChange);

        return () => {
            fileCenter.off('statusChange', handleStatusChange);
        };
    }, [fileIdList]);

    return { fileInfoList }
}

export default useFileStatus;