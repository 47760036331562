import React, { createContext, useState } from 'react';

export interface WH {
    width: number;
    height: number;
}

export interface IFileCenterContext {
    isLoggedIn: boolean;
    setIsLoggedIn: (isLoggedIn: boolean) => void;
    userInfo: any;
    setUserInfo: (userInfo: any) => void;

    fileIdList: string[];
    setFileIdList: (fileIdList: string[]) => void;
    //水印类型
    waterMarkType: string;
    setWaterMarkType: (waterMarkType: string) => void;
    //水印内容
    waterMarkTemplate: string,
    setWaterMarkTemplate: (waterMarkTemplate: string) => void;
    fontName: string,
    setFontName: (fontName: string) => void;
    // 在原始图片上的字体大小
    fontSize: number,
    setFontSize: (fontSize: number) => void;
    fontColor: string,
    setFontColor: (fontColor: string) => void;
    // 字体轮廓颜色
    fontStrokeColor: string,
    setFontStrokeColor: (fontStrokeColor: string) => void;
    // 字体轮廓宽度
    fontStrokeWidth: number,
    setFontStrokeWidth: (fontStrokeWidth: number) => void,
    isBold: boolean,
    setIsBold: (isBold: boolean) => void,
    isItalic: boolean,
    setIsItalic: (isItalic: boolean) => void,
    //方向
    angel: number,
    setAngel: (angel: number) => void;
    //透明度
    tran: number,
    setTran: (tran: number) => void;
    //位置类型，0 平铺 1 九宫格 2 自定义
    posType: number,
    setPosType: (posType: number) => void;
    // 水印中心相对于图片的偏移量
    offsetLeft: number,
    setOffsetLeft: (offsetLeft: number) => void;
    // 水印中心相对于图片的偏移量
    offsetTop: number,
    setOffsetTop: (offsetTop: number) => void;
    cellIndex: number,
    setCellIndex: (cellIndex: number) => void;
    // 预览页面图片容器的宽高
    imageEditViewWH: WH,
    setImageEditViewWH: (imageEditViewWH: WH) => void;
}

export const FileCenterContext = createContext<IFileCenterContext>({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) => { },
    userInfo: null,
    setUserInfo: (userInfo: any) => { },

    fileIdList: [],
    setFileIdList: (fileIdList: string[]) => { },
    //水印类型
    waterMarkType: 'text',
    setWaterMarkType: (waterMarkType: string) => { },
    //水印内容模板
    waterMarkTemplate: '',
    setWaterMarkTemplate: (waterMarkTemplate: string) => { },
    fontName: 'Microsoft YaHei',
    setFontName: (fontName: string) => { },
    // 在原始图片上的字体大小
    fontSize: 48,
    setFontSize: (fontSize: number) => { },
    fontColor: '#1677ff',
    setFontColor: (fontColor: string) => { },
    fontStrokeColor: '',
    setFontStrokeColor: (fontStrokeColor: string) => {},
    fontStrokeWidth: 0,
    setFontStrokeWidth: (fontStrokeWidth: number) => {},
    isBold: false,
    setIsBold: (isBold: boolean) => { },
    isItalic: false,
    setIsItalic: (isItalic: boolean) => { },
    //方向
    angel: 0,
    setAngel: (angel: number) => { },
    //透明度
    tran: 0,
    setTran: (tran: number) => { },
    //位置类型，0 平铺 1 九宫格 2 自定义
    posType: 0,
    setPosType: (posType: number) => { },
    // 水印内容相对于图片的偏移量
    offsetLeft: 0,
    setOffsetLeft: (offsetLeft: number) => { },
    // 水印内容相对于图片的偏移量
    offsetTop: 0,
    setOffsetTop: (offsetTop: number) => { },
    cellIndex: 0,
    setCellIndex: (cellIndex: number) => { },
    imageEditViewWH: { width: 0, height: 0 },
    setImageEditViewWH: (imageEditViewWH: WH) => { },
})

interface IProps {
    children: React.ReactNode;
}

const tokenStr = localStorage.getItem('token');
const originUserInfoStr = localStorage.getItem('userInfo');
const originUserInfo = originUserInfoStr ? JSON.parse(originUserInfoStr) : null;


const FileCenterProvider = (props: IProps) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!(tokenStr && originUserInfo));
    const [userInfo, setUserInfo] = useState<any>(originUserInfo);

    const [fileIdList, setFileIdList] = useState<any>([]);
    const [waterMarkType, setWaterMarkType] = useState<string>('text');
    const [waterMarkTemplate, setWaterMarkTemplate] = useState<string>('');
    const [fontName, setFontName] = useState<string>('Microsoft YaHei');
    const [fontSize, setFontSize] = useState<number>(48);
    const [fontColor, setFontColor] = useState<string>('#1677ff');
    const [fontStrokeColor, setFontStrokeColor] = useState<string>('');
    const [fontStrokeWidth, setFontStrokeWidth] = useState<number>(1);
    const [isBold, setIsBold] = useState<boolean>(false);
    const [isItalic, setIsItalic] = useState<boolean>(false);
    const [angel, setAngel] = useState<number>(0);
    const [tran, setTran] = useState<number>(0);
    const [posType, setPosType] = useState<number>(2);
    const [offsetLeft, setOffsetLeft] = useState<number>(50);
    const [offsetTop, setOffsetTop] = useState<number>(50);
    const [cellIndex, setCellIndex] = useState<number>(4);
    const [imageEditViewWH, setImageEditViewWH] = useState<WH>({ width: 0, height: 0 });

    return (
        <FileCenterContext.Provider value={{
            isLoggedIn,
            setIsLoggedIn,
            userInfo,
            setUserInfo,

            fileIdList,
            setFileIdList,
            waterMarkType,
            setWaterMarkType,
            waterMarkTemplate,
            setWaterMarkTemplate,
            fontName,
            setFontName,
            fontSize,
            setFontSize,
            fontColor,
            setFontColor,
            fontStrokeColor,
            setFontStrokeColor,
            fontStrokeWidth,
            setFontStrokeWidth,
            isBold,
            setIsBold,
            isItalic,
            setIsItalic,
            angel,
            setAngel,
            tran,
            setTran,
            posType,
            setPosType,
            offsetLeft,
            setOffsetLeft,
            offsetTop,
            setOffsetTop,
            cellIndex,
            setCellIndex,
            imageEditViewWH,
            setImageEditViewWH,
        }}>
            {props.children}
        </FileCenterContext.Provider>
    );
};

export default FileCenterProvider;
