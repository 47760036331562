import { Modal, Button } from 'antd';
import useFileStatus from '@/components/hooks/fileStatusHook';
import { fileCenter, FileStatus } from '@/FileCenter';

interface IProps {
    showProcessModal: boolean;
    onProcessClose: () => void;
}

function FileProcess(props: IProps) {
    const { showProcessModal, onProcessClose } = props;
    const fileInfoList = useFileStatus().fileInfoList;
    const count = fileInfoList.length;
    const successCount = fileInfoList.filter(fileInfo => fileInfo.status === FileStatus.Success).length;
    const pendingCount = fileInfoList.filter(fileInfo => fileInfo.status === FileStatus.Pending).length;

    const handleCancel = () => {
        if (pendingCount > 0) { // 正在处理，此时要取消任务，将所有的pending状态的文件设置为未处理
            fileInfoList.forEach(fileInfo => {
                if (fileInfo.status === FileStatus.Pending) {
                    fileInfo.status = FileStatus.Ready
                }
            });
            fileCenter.emit('statusChange');
            return;
        }
        // 如果没有正在处理的任务，则直接关闭modal
        onProcessClose();
        // 取消任务的逻辑
    };

    return (
        <Modal
            maskClosable={false}
            open={showProcessModal}
            title={pendingCount > 0 ? '正在处理中...' : '处理完成'}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    {pendingCount > 0 ? '取消' : '关闭'}
                </Button>,
            ]}
        >
            {pendingCount > 0 && (
                <div>已处理 {count - pendingCount}/{count}个</div>
            )}
            {pendingCount === 0 && (
                <div>{successCount}个文件处理成功，共{count}个。点击【任务列表】按钮可以查看详情</div>
            )}
        </Modal>
    );
}

export default FileProcess;