import './ThumbnailList.css';
import React, { useContext } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { fileCenter, FileInfo } from '@/FileCenter';
import { FileCenterContext } from '@/FileCenterProvider';
import Upload from '../components/Upload';
import ThumbnailItem from './ThumbnailItem';


interface IProps {
    onSelect: (fileInfo: FileInfo) => void;
    onRemove: (fileId: string) => void;
    selectedItem: FileInfo | undefined
}

function ThumbnailList(props: IProps) {
    const { selectedItem, onSelect, onRemove } = props;
    const { fileIdList } = useContext(FileCenterContext);
    const fileInfoList = fileIdList.map(fileId => fileCenter.getFileInfo(fileId)).filter(fileInfo => fileInfo !== undefined) as FileInfo[]

    return (
        <div className='thumbnail-container'>
            <Upload className='thumbnail-btn-container' enableDrag={false}>
                {uploadProps => (
                    <>
                        <div className='btn-pointer' onClick={uploadProps.onClickUpload}>
                            <div className='circle-add'>
                                +
                            </div>
                            <span className='text-add'>添加</span>
                        </div>
                        <div className='btn-pointer' onClick={uploadProps.onClear}>
                            <ClearOutlined className="text-clear" />
                            <span className='text-clear'>清空</span>
                        </div>
                    </>
                )}
            </Upload>
            <div className='thumbnail-list-container'>
                {fileInfoList.map(fileInfo => <ThumbnailItem key={fileInfo.fileId} fileInfo={fileInfo} selectedItem={selectedItem} onSelect={onSelect} onRemove={onRemove} />)}
            </div>
        </div>
    )
}

export default React.memo(ThumbnailList)