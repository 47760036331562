import EXIF from "exif-js";

interface FileReadResult {
    dataUrl: string,
    blob: ArrayBuffer | null,
    img: HTMLImageElement
}

enum FileStatus {
    Ready = 'Ready', // 未处理状态
    Pending = 'Pending',
    Success = 'Success',
    Failure = 'Failure',
}

type EventCallback = (...args: any[]) => void;

function generateUniqueId(): string {
    return Date.now() + '-' + Math.floor(Math.random() * 10000);
}

class FileInfo {
    private _fileCenter: FileCenter;
    private _fileId: string;
    private _file: File;
    private _image: HTMLImageElement;
    private _fileDataUrl: string;
    private _fileHandle: FileSystemFileHandle | null;
    private _imageTakenTime: Date;
    private _status: FileStatus = FileStatus.Ready;

    constructor(fileCenter: FileCenter, file: File, image: HTMLImageElement, fileDataUrl: string, fileHandle?: FileSystemFileHandle) {
        this._fileCenter = fileCenter
        this._fileId = generateUniqueId()
        this._file = file
        this._image = image
        this._fileDataUrl = fileDataUrl
        this._fileHandle = fileHandle || null
        this._imageTakenTime = new Date()
    }

    get fileId() {
        return this._fileId
    }

    get file() {
        return this._file
    }

    get image() {
        return this._image
    }

    get fileDataUrl() {
        return this._fileDataUrl
    }

    get fileHandle() {
        return this._fileHandle
    }

    get imageTakenTime() {
        return this._imageTakenTime
    }

    set imageTakenTime(date: Date) {
        this._imageTakenTime = date
    }

    get status() {
        return this._status
    }

    set status(status: FileStatus) {
        this._status = status
    }

    setStatus(status: FileStatus) {
        this._status = status
        this._fileCenter.emit('statusChange')
    }

    getImageType(): string {
        const fileName = this.file.name;
        const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        switch (extension) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            default:
                return 'unknown';
        }
    }
}

class FileCenter {
    private fileMap: Map<string, FileInfo>
    private eventListeners: Map<string, EventCallback[]>;

    constructor() {
        this.fileMap = new Map<string, FileInfo>()
        this.eventListeners = new Map<string, EventCallback[]>();
    }

    getFileInfo(fileId: string): FileInfo | undefined {
        return this.fileMap.get(fileId)
    }

    setFileInfo(fileId: string, fileInfo: FileInfo) {
        return this.fileMap.set(fileId, fileInfo)
    }

    removeFileInfo(fileId: string) {
        return this.fileMap.delete(fileId)
    }

    getFileInfoList() {
        const result: FileInfo[] = []
        this.fileMap.forEach((value, key) => {
            result.push(value)
        })
        return result
    }

    getCount(): number {
        return this.fileMap.size
    }

    clear() {
        this.fileMap.clear()
    }

    getTest() {
        return "FileCenter"
    }

    getImageTakenTime(blob: ArrayBuffer | null, file: File): Date {
        const exif = EXIF.readFromBinaryFile(blob);
        if (exif?.DateTimeOriginal) {
            // EXIF 日期格式通常为 "YYYY:MM:DD HH:mm:ss"
            const [date, time] = exif.DateTimeOriginal.split(' ');
            const [year, month, day] = date.split(':');
            const [hour, minute, second] = time.split(':');
            return new Date(year, month - 1, day, hour, minute, second)

        } else {
            // 如果没有拍摄时间，返回文件的最后修改时间
            return new Date(file.lastModified);
        }
    }

    private readFile(file: File): Promise<FileReadResult> {
        return new Promise((resolve, reject) => {
            let exifLoaded = false
            let imageLoaded = false

            const img = new Image();
            const res: FileReadResult = { dataUrl: '', blob: null, img }
            const exifReader = new FileReader();
            exifReader.onload = function (e) {
                exifLoaded = true
                if (!e.target?.result) return;
                res.blob = e.target.result as ArrayBuffer
                if (imageLoaded) {
                    resolve(res)
                }
            }
            exifReader.readAsArrayBuffer(file);

            const imageReader = new FileReader();
            imageReader.onload = function (e) {
                imageLoaded = true
                if (!e.target) return;
                if (typeof e.target.result !== 'string') {
                    return;
                }

                img.src = e.target.result;
                res.dataUrl = e.target.result;
                if (exifLoaded) {
                    resolve(res)
                }
            }
            imageReader.readAsDataURL(file);
        });
    }

    // 优化后的 readFile 方法，采用URL.createObjectURL比readAsDataURL的base64更快
    private readFile2(file: File): Promise<FileReadResult> {
        return new Promise((resolve, reject) => {
            let exifLoaded = false
            let imageLoaded = false

            const img = new Image();
            const res: FileReadResult = { dataUrl: '', blob: null, img }
            const exifReader = new FileReader();
            exifReader.onload = function (e) {
                exifLoaded = true
                if (!e.target?.result) return;
                res.blob = e.target.result as ArrayBuffer
                if (imageLoaded) {
                    resolve(res)
                }
            }
            exifReader.readAsArrayBuffer(file);


            const objectURL = URL.createObjectURL(file);
            img.src = objectURL;
            img.onload = () => {
                imageLoaded = true
                res.dataUrl = objectURL;
                if (exifLoaded) {
                    resolve(res)
                }
            }
        });
    }

    handleUpload(fileList: FileList, cb: (list: string[]) => void) {
        const self = this
        const startTime = Date.now()
        const files = Array.isArray(fileList) ? fileList : Array.from(fileList);
        files.forEach(async file => {
            // const { blob, dataUrl, img } = await self.readFile(file)
            const { blob, dataUrl, img } = await self.readFile2(file)

            const fileInfo = new FileInfo(self, file, img, dataUrl)
            fileInfo.imageTakenTime = self.getImageTakenTime(blob, file)
            self.setFileInfo(fileInfo.fileId, fileInfo)
            const loadedFileIds = self.getFileInfoList().map(fileInfo => fileInfo.fileId)
            // 只要加载完一个文件就进入图片预览页面，而不是等所有文件都加载完
            cb && cb(loadedFileIds)
            if (loadedFileIds.length === files.length) {
                const endTime = Date.now()
                console.log('spend ', endTime - startTime)
            }
        })
    }

    async handleUploadFileHandles(fileHandles: FileSystemFileHandle[], cb: (list: string[]) => void) {
        const self = this
        const startTime = Date.now()
        fileHandles.forEach(async fileHandle => {
            const file = await fileHandle.getFile();
            // const { blob, dataUrl, img } = await self.readFile(file)
            const { blob, dataUrl, img } = await self.readFile2(file)

            const fileInfo = new FileInfo(self, file, img, dataUrl, fileHandle)
            fileInfo.imageTakenTime = self.getImageTakenTime(blob, file)
            self.setFileInfo(fileInfo.fileId, fileInfo)
            const loadedFileIds = self.getFileInfoList().map(fileInfo => fileInfo.fileId)
            // 只要加载完一个文件就进入图片预览页面，而不是等所有文件都加载完
            cb && cb(loadedFileIds)
            if (loadedFileIds.length === fileHandles.length) {
                const endTime = Date.now()
                console.log('spend ', endTime - startTime)
            }
        })

    }

    on(event: string, callback: EventCallback) {
        if (!this.eventListeners.has(event)) {
            this.eventListeners.set(event, []);
        }
        this.eventListeners.get(event)!.push(callback);
    }

    off(event: string, callback: EventCallback) {
        if (!this.eventListeners.has(event)) return;
        const listeners = this.eventListeners.get(event)!;
        const index = listeners.indexOf(callback);
        if (index !== -1) {
            listeners.splice(index, 1);
        }
    }

    emit(event: string, ...args: any[]) {
        if (!this.eventListeners.has(event)) return;
        const listeners = this.eventListeners.get(event)!;
        listeners.forEach(callback => callback(...args));
    }

    setAllFilStatus(status: FileStatus) {
        this.fileMap.forEach((fileInfo) => {
            fileInfo.status = status;
        })
        this.emit('statusChange');
    }
}

const fileCenter = new FileCenter();
export { FileInfo, fileCenter, FileStatus };

